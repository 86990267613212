import { useState, useCallback, useMemo } from 'react';
import { usePopper } from 'react-popper';
import debounce from 'lodash/debounce';
import { useKey } from 'react-use';

const getPopperOptions = (options) => ({
  placement: 'bottom-start',
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, 0],
      },
    },
  ],
  ...options,
});

export const useTooltip = (id, options = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  // Can't use useRef due to the implementation of usePopper - refs must be stored as state
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, getPopperOptions(options));

  const showTooltip = useCallback(() => {
    setIsOpen(true);
  }, [setIsOpen]);

  const debounceShowTooltip = useMemo(() => debounce(showTooltip, 400), [showTooltip]);

  const hideTooltip = useCallback(() => {
    setIsOpen(false);
    debounceShowTooltip.cancel();
  }, [setIsOpen, debounceShowTooltip]);

  useKey('Escape', hideTooltip);

  const onBlurCallback = ({ relatedTarget, nativeEvent }) => {
    if (nativeEvent?.stopImmediatePropagation) {
      nativeEvent.stopImmediatePropagation();
    }
    // Hide tooltip if navigating out of the tooltip or the CTA
    // If user clicks outside the tooltip to a non-focusable element, relatedTarget will be null
    const isValidHTMLNode = relatedTarget instanceof Element;
    if (!isValidHTMLNode || (!referenceElement?.contains(relatedTarget) && !popperElement?.contains(relatedTarget))) {
      hideTooltip();
    }
  };

  return {
    isOpen,
    triggerProps: {
      id,
      ref: setReferenceElement,
      onMouseEnter: debounceShowTooltip,
      onClick: debounceShowTooltip,
      onFocus: debounceShowTooltip,
      onMouseLeave: onBlurCallback,
      onBlur: onBlurCallback,
    },
    tooltipProps: {
      'aria-labelledby': id,
      ref: setPopperElement,
      style: styles.popper,
      ...attributes.popper,
      isOpen,
      zIndex: 'tooltip',
      onMouseLeave: onBlurCallback,
      onBlur: onBlurCallback,
    },
  };
};
