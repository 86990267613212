import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Flex, Box, Icon, Heading, NakedButton, Modal as RooModal } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet } from 'styled-system';
import { rem } from 'polished';
import theme from 'lib/theme';
import { mediaQuery } from 'lib/styledSystem';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { useSelector } from 'react-redux';
import { getIsMobileApp } from 'store/ui/uiSelectors';

const HEADER_HEIGHT = 52;

const Header = styled(Flex)`
  justify-content: space-between;
  padding: ${themeGet('space.4')};
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${themeGet('colors.greys.alto')};
  align-items: center;
`;

const Body = styled(Box)`
  position: absolute;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  ${mediaQuery.minWidth.sm} {
    ${(props) =>
      !props.fullScreen &&
      css`
        position: relative;
        left: auto;
        left: initial;
        right: auto;
        right: initial;
        top: auto;
        top: initial;
        bottom: auto;
        bottom: initial;
      `}
  }
`;

const CloseIcon = styled(Icon)`
  cursor: pointer;
`;
CloseIcon.displayName = 'CloseIcon';

const Modal = ({ title, children, fullScreen, padding, footerComponent, ...modalProps }) => {
  const { isLessThanBreakpoint } = useBreakpoints();
  const isMobileApp = useSelector(getIsMobileApp);

  const dynamicWidth = isMobileApp ? '90%' : '100%';
  const height = isMobileApp ? '90%' : '100%';

  const width = isLessThanBreakpoint(0) || fullScreen ? dynamicWidth : rem(660);

  return (
    <RooModal
      width={width}
      {...modalProps}
      style={{
        overlay: { zIndex: theme.zIndices.modal },
        content: {
          height: isLessThanBreakpoint(0) || fullScreen ? height : 'auto',
          maxHeight: isLessThanBreakpoint(0) || fullScreen ? height : '90%',
          width,
          borderRadius: isMobileApp ? themeGet('radii.rounded') : '0px',
        },
      }}
    >
      <Header data-testid="modal-header">
        <Box width="24px">&nbsp;</Box>
        <Heading.h4 mb={0} data-testid="title" fontSize={'base'} style={{ fontFamily: 'Jetstar' }}>
          {title}
        </Heading.h4>
        <NakedButton width="24px" onClick={modalProps.onRequestClose}>
          <CloseIcon name="close" data-testid="close" />
        </NakedButton>
      </Header>
      <Body fullScreen={fullScreen} padding={padding} data-testid="modal-body">
        {children}
      </Body>
      {footerComponent && (
        <Box mt="auto" position="relative" data-testid="modal-footer">
          {footerComponent()}
        </Box>
      )}
    </RooModal>
  );
};

Modal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node]),
  children: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  footerComponent: PropTypes.func,
};

Modal.defaultProps = {
  title: '',
  fullScreen: false,
  padding: 8,
  footerComponent: null,
};

export default memo(Modal);
